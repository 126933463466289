var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meters"},[_c('v-row',[_c('v-col',[_c('breadcrumb',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-col',{staticClass:"text-right"},[(_vm.can('meter-create'))?_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddMeterDialog = true}}},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTablePlus)+" ")]),_vm._v(" Add Meter ")],1):_vm._e()],1)],1),_c('v-card',[_c('v-card-title',[_c('v-col',{staticClass:"col-md-2"},[_vm._v(" Meters ")]),_c('v-col',{staticClass:"col-md-1"},[_c('refresh-button',{attrs:{"loading":_vm.isMetersRefreshing},on:{"click":function($event){return _vm.refreshMeters()}}})],1),_c('v-col',{staticClass:"col-md-6"},[_c('table-filter',{attrs:{"show-date-filter":false,"show-meter-valve-status-filter":true},on:{"filter":_vm.applyFilters}})],1),_c('v-col',{staticClass:"col-md-3"},[_c('search-input',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.meters,"loading":_vm.isMetersLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.meters.length > 0)?_c('tbody',_vm._l((items),function(meter,index){return _c('tr',{key:meter.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onMeterClick(meter.id)}}},[_c('td',[(meter.user)?_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(meter.user.account_number))]):_vm._e()]),_c('td',[_vm._v(_vm._s(meter.number))]),_c('td',[_vm._v(_vm._s(meter.location))]),_c('td',[_c('v-chip',{class:{ success: meter.mode === 1, secondary: meter.mode === 0 },attrs:{"text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("formatMeterMode")(meter.mode))+" ")])],1),_c('td',[(meter.mode == 1)?_c('span',[_vm._v(_vm._s(meter.type.name))]):_c('span',[_vm._v("Not applicable")])]),_c('td',[(meter.user)?_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(meter.user.name))]):_c('span',[_vm._v("No customer")])]),_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[(meter.mode == 1 && meter.type.name != 'Prepaid')?_c('span',[_c('v-switch',{attrs:{"inset":"","loading":_vm.isToggleValveSwitchLoading && index == _vm.switchIndex,"disabled":_vm.isToggleValveSwitchLoading && index == _vm.switchIndex,"false-value":0,"true-value":1,"label":meter.valve_status ? 'Open' : 'Closed'},on:{"change":function($event){return _vm.showToggleValveConfirmDialog(meter.id, meter.valve_status, index)}},model:{value:(meter.valve_status),callback:function ($$v) {_vm.$set(meter, "valve_status", $$v)},expression:"meter.valve_status"}})],1):_c('span',[_vm._v("Not applicable")])]),(_vm.can('meter-edit') || _vm.can('meter-delete'))?_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.can('meter-edit'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){return _vm.editMeter(meter)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.can('meter-delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){return _vm.showDeleteMeterDialog(meter.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1):_vm._e()])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMetersLoading),expression:"!isMetersLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No meters found ")])])])]}}])}),_c('v-divider'),_c('data-table-pagination',{attrs:{"page":_vm.pagination.page,"page-count":_vm.pagination.pageCount},on:{"page-change":_vm.onPageChange,"items-per-page-change":_vm.onItemsPerPageChange}})],1),_c('add-meter-dialog',{attrs:{"show-dialog":_vm.showAddMeterDialog,"meter":_vm.meter},on:{"close":function($event){_vm.showAddMeterDialog = false
      _vm.meter = {}},"meter-added":function($event){return _vm.getMeters()}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"agree-text":'Delete'},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteMeter()}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmToggleValveDialog,"is-agree-button-loading":_vm.isToggleValveSwitchLoading,"message":'Are you sure you want to switch off valve of this meter?',"agree-text":'Switch Off'},on:{"cancel":function($event){return _vm.cancelToggleValveStatusUpdate()},"agree":function($event){return _vm.updateValveStatus()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }